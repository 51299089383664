<template>
    <div v-loading="$waiting.is('loading')">
        <el-dialog
            width="1400px"
            top="40px"
            title="Återbetalning"
            :visible="visible"
            :close-on-click-modal="true"
            :before-close="resetFormAndClose"
            @closed="resetForm"
            :lock-scroll="false"
            @open="getDetails"
            :show-close="true"
            class="modal-edit-payment-container"
        >
            <el-row :gutter="10">
                <el-col :span="9">
                    <p class="ml-2 text-lg">Klient</p>
                    <div class="relative border rounded-lg py-4 px-5">
                        <ClientsSearch @on-selected="clientSelected" :selected="selectedClientProp" class="mb-2" />
                        <el-button v-if="setClientButtonVisable" @click="setClient" type="success">Spara</el-button>
                        <el-descriptions :column="1">
                            <el-descriptions-item label="Namn">{{ transaction.clientName }}</el-descriptions-item>
                            <el-descriptions-item label="Nummer">{{ transaction.clientNumber }}</el-descriptions-item>
                            <el-descriptions-item label="Bank">{{ transaction.clientBank }}</el-descriptions-item>
                            <el-descriptions-item label="Kontonummer">{{ transaction.clientBankAccountNumber }}</el-descriptions-item>
                            <el-descriptions-item label="Telefon">{{ transaction.clientPhoneNumber }}</el-descriptions-item>
                            <el-descriptions-item label="Epost">{{ transaction.clientEmail }}</el-descriptions-item>
                        </el-descriptions>
                        <el-button @click="copyClient" type="success">Kopiera till betalning</el-button>
                    </div>
                </el-col>
                <el-col :span="6">
                    <p class="ml-2 text-lg">Betalare</p>
                    <div class="relative border rounded-lg py-4 px-5">
                        <el-descriptions :column="1">
                            <el-descriptions-item label="Namn">{{ transaction.payerName }}</el-descriptions-item>
                            <el-descriptions-item label="Typ">{{ transaction.payerAccountType }}</el-descriptions-item>
                            <el-descriptions-item label="Kontonummer">{{ transaction.payerAccountNumber }}</el-descriptions-item>
                            <el-descriptions-item label="Org nr">{{ transaction.payerOrgNo }}</el-descriptions-item>
                            <el-descriptions-item v-if="transaction.textStruct != null && transaction.textStruct.length > 0" label="Meddelande">{{ transaction.textStruct }}</el-descriptions-item>
                            <el-descriptions-item v-if="transaction.textUnstruct != null && transaction.textUnstruct.length > 0" label="Meddelande">{{ transaction.textUnstruct }}</el-descriptions-item>
                            <el-descriptions-item v-if="transaction.message != null" label="Meddelande">{{ transaction.message }}</el-descriptions-item>
                        </el-descriptions>
                        <el-button @click="copyPayer" type="success">Kopiera till betalning</el-button>
                    </div>
                </el-col>
                <el-col :span="9">
                    <p class="ml-2 text-lg">Klient</p>
                    <div class="relative border rounded-lg py-4 px-5">
                        <el-form ref="form" :model="formData" :rules="rulesRulesForSending" :hide-required-asterisk="true">
                            <el-form-item label="Anledning" prop="reason">
                                <el-input v-model="formData.reason" />
                            </el-form-item>

                            <el-form-item label="Betalnings typ" prop="accountType">
                                <el-select v-model="formData.accountType" class="w-full">
                                    <el-option v-for="item in accountTypes" :key="item.value" :value="parseInt(item.value)" :label="item.name" />
                                </el-select>
                            </el-form-item>

                            <el-form-item label="Konto nr" prop="accountNumber">
                                <el-input v-model="formData.accountNumber" />
                            </el-form-item>

                            <el-form-item label="Mottagare" prop="recipientName">
                                <el-input v-model="formData.recipientName" />
                            </el-form-item>

                            <el-form-item label="Kontaktperson" prop="contactPerson">
                                <el-input v-model="formData.contactPerson" />
                            </el-form-item>

                            <el-form-item label="Belopp" prop="amount">
                                <el-input v-model="formData.amount" disabled />
                            </el-form-item>

                            <el-form-item label="Meddelande till mottagaren" prop="message">
                                <el-input v-model="formData.message" />
                            </el-form-item>

                            <el-form-item label="Intern notering" prop="comment">
                                <el-input type="textarea" rows="3" resize="none" v-model="formData.comment" :disabled="formData.refundStatus === 2" />
                            </el-form-item>
                            <el-button v-if="formData && formData.refundStatus <= 1" @click="saveDraft" type="primary" :loading="$waiting.is('refund.sending')">Spara utkast</el-button>
                            <!-- <el-button v-if="formData && formData.refundStatus <= 1" @click="markAsSent" type="danger" :loading="$waiting.is('refund.sending')">Markera som skickat</el-button> -->
                            <el-button v-if="formData && formData.refundStatus < 2" @click="refund" type="success" :loading="$waiting.is('refund.sending')">Skicka</el-button>
                            <el-button v-if="formData && formData.refundStatus === 1" @click="deleteRefund" type="primary" :loading="$waiting.is('refund.*')">Ta bort</el-button>
                        </el-form>
                    </div>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModal" type="primary">Stäng</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Api from "../bank_files.api";
export default {
    props: {
        ids: {
            type: Object,
            default: () => {},
        },
        subId: {
            type: Number,
            default: 0,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        ClientsSearch: () => import("@/components/clientsSearch.vue"),
        // InputCalc: () => import(/* webpackChunkName: "BaseInputNumericCalc" */ "@/components/baseInputNumericCalc.vue"),
    },

    data() {
        return {
            transaction: {},
            formData: {
                reason: null,
                accountType: 0,
                accountNumber: null,
                recipientName: null,
                contactPerson: null,
                amount: null,
                currency: null,
                message: null,
                comment: null,
                refundStatus: null,
            },
            accountTypes: [],
            rulesRulesForSending: {
                reason: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                accountType: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                accountNumber: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                recipientName: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                currency: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                message: [{ required: true, message: "Nödvändig", trigger: "blur" }],
            },
            selectedClientProp: {},
            setClientButtonVisable: false,
        };
    },
    async created() {
        this.loading = true;
        this.formData = {};
        this.loading = false;
    },
    methods: {
        async getData() {
            this.$waiting.start("refund.loading");
            this.formData = await Api.getRefund(this.transaction.id);
            this.$waiting.end("refund.loading");
        },
        async getAccountTypes() {
            this.accountTypes = await Api.getAccountTypes();
        },
        async getDetails() {
            this.$waiting.start("loading");
            this.transaction = await Api.getChild(this.ids.id, this.ids.subId);
            this.selectedClientProp = { value: this.transaction.clientId };
            this.getAccountTypes();
            this.getData();
            console.log("🚀 - file: BankFilesRefundModal.vue:79 - getDetails - this.transaction:", this.transaction);
            // const invoiceData = await Api.getInvoiceDetails(this.paymentId, this.invoiceId);
            // this.invoiceData = invoiceData;
            // this.formData.reminderFee = invoiceData.reminderFee;
            // this.formData.interestAmount = invoiceData.interestAmount;
            // this.formData.paymentAmount = invoiceData.invoicePayment;
            this.$waiting.end("loading");
        },
        resetForm() {},
        resetFormAndClose() {
            this.$emit("close");
        },
        closeModal() {
            this.$emit("close");
        },
        async refund() {
            this.activeValidationRules = "sending";
            await this.$nextTick();
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.saveDraft();

            const confirm = await this.$dialog.title("Bekräftelse").confirm("Are you sure that you want to refund?");
            if (!confirm) return;

            this.$waiting.start("refund.sending");
            await Api.sendRefund(this.ids.subId, this.formData);
            this.$waiting.end("refund.sending");
            this.getData();
        },
        async saveDraft() {
            this.activeValidationRules = "saving";
            await this.$nextTick();
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));

            this.$waiting.start("refund.sending");
            if (this.formData.refundStatus === 0) {
                await Api.saveNewDraft(this.ids.subId, this.formData);
            } else {
                await Api.saveDraft(this.ids.subId, this.formData);
            }
            this.$waiting.end("refund.sending");
            this.getData();
        },
        async deleteRefund() {
            this.$waiting.start("refund.deleting");
            await Api.deleteRefund(this.ids.subId);
            this.$waiting.end("refund.deleting");
            this.resetFormData();
            this.getData();
        },

        resetFormData() {
            this.$refs.form.clearValidate();
            this.formData = {
                reason: null,
                accountType: 0,
                accountNumber: null,
                recipientName: null,
                contactPerson: null,
                amount: null,
                currency: null,
                message: null,
                comment: null,
            };
        },
        async clientSelected(data) {
            console.log("🚀 - file: BankFilesRefundModal.vue:229 - clientSelected - data:", data);
            this.newClientId = data.value;
            if (this.newClientId != this.transaction.clientId) {
                this.setClientButtonVisable = true;
            } else {
                this.setClientButtonVisable = false;
            }
        },
        async setClient() {
            this.$waiting.start("refund.sending");
            await Api.setClient(this.ids.id, this.ids.subId, this.newClientId);
            this.$waiting.end("refund.sending");
            this.getDetails();
        },
        copyClient() {
            this.$set(this.formData, "reason", "Inbetalning");
            this.$set(this.formData, "accountType", 3);
            this.$set(this.formData, "accountNumber", this.transaction.clientBankAccountNumber);
            this.$set(this.formData, "recipientName", `KB ${this.transaction.clientNumber.substring(2)} ${this.transaction.message}`);
            // this.$set(this.formData, "message", `KB ${this.transaction.clientNumber.substring(2)} ${this.transaction.message}`);
            this.$set(this.formData, "message", `${this.transaction.message}`);
        },
        copyPayer() {
            this.$set(this.formData, "reason", "Återbetalning");
            this.$set(this.formData, "accountType", this.transaction.payerAccountType);
            this.$set(this.formData, "accountNumber", this.transaction.payerAccountNumber);
            this.$set(this.formData, "recipientName", `Åter ${this.transaction.payerName} ${this.transaction.message}`);
            this.$set(this.formData, "message", `Åter  ${this.transaction.message}`);
        },
    },
};
</script>
<style scoped>
.modal-edit-payment-container .el-input-number .el-input__inner {
    text-align: left !important;
}
</style>
