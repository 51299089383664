<template>
    <el-dialog width="600px" top="40px" title="Kommentarer" :before-close="closeModal" :visible.sync="visible" :close-on-click-modal="false" @closed="resetModalState" @open="open">
        <div v-loading="$waiting.is('modal.loading')">
            <div class="mb-8" v-for="comment in commentsData" :key="comment.commentId">
                <div class="flex px-2 justify-between">
                    <p>
                        {{ comment.individualName }}
                    </p>
                    <p>
                        {{ formatDate(comment.createdDate) }}
                    </p>
                </div>
                <p class="p-4 rounded-lg bg-gray-100">
                    {{ comment.text }}
                </p>
            </div>
            <div class="border-t pt-8">
                <el-form ref="form" :model="formData" :rules="rules">
                    <el-form-item prop="newComment">
                        <el-input v-model="formData.newComment" type="textarea" placeholder="Lägg till ny kommentar" resize="none" rows="5" />
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" type="primary">Stäng</el-button>
            <el-button @click="addNewComment" type="primary">Lägg till</el-button>
        </span>
    </el-dialog>
</template>
<script>
import moment from "moment";
import Api from "../bank_files.api";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        sourceId: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            commentsData: [],
            formData: {},
            rules: {
                newComment: [{ required: true, message: "Nödvändig", trigger: "blur" }],
            },
        };
    },

    methods: {
        open() {
            this.getAllComments();
        },

        async getAllComments() {
            this.$waiting.start("modal.loading");
            this.commentsData = await Api.getCommentsBySourceId(this.sourceId);
            this.$waiting.end("modal.loading");
        },

        closeModal() {
            this.$refs.form.resetFields();
            this.$emit("close");
        },

        dataUploaded() {
            this.$emit("refresh");
        },

        async addNewComment() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));

            this.$waiting.start("modal.loading");
            await Api.addNewComment(this.sourceId, this.formData.newComment);
            this.$notify.success({ title: "Utfört" });
            this.formData.newComment = "";
            this.getAllComments();
        },

        formatDate(date) {
            return moment(date).format("YYYY-MM-DD HH:mm");
        },

        resetModalState() {
            this.formData.newComment = "";
        },
    },
};
</script>
